import React from "react";
import { AboutHeader, AboutDetails, Values } from "./Styled";

const About = () => {

  return (
    <>
      <AboutHeader>
        <h1>Zegates,  where people meets <br /> <span>Innovation</span></h1>
      </AboutHeader>
      <AboutDetails>
        <div>
          <div style={{
            borderBottom: '1px solid #000',
          }}>
            <h1>We are digital natives and we value three things</h1>
          </div>
          <div style={{
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
          }}>
            <h2>Satisfaction</h2>
            <p>We always prioritize customer satisfaction as a core value and
              consistently delivering high-quality software solutions that meet or exceed customer expectations.
              It involves actively listening to customer needs, providing excellent customer support,
              and maintaining open lines of communication to ensure that clients are
              happy with the services provided.</p>
          </div>
        </div>
        <div>
          <div>
          </div>
          <div style={{
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
          }}>
            <h2>Innovation</h2>
            <p> In the fast-paced world of technology, innovation is crucial for us to stay competitive.
              Emphasizing innovation as a core value encourages employees to think creatively,
              explore new technologies, and find innovative solutions to complex problems.
              This can involve investing in research and development,
              fostering a culture of experimentation, and encouraging
              continuous learning and improvement.</p>
          </div>
        </div>
        <div>
          <div>
          </div>
          <div style={{
            borderLeft: '1px solid #000',
          }}>
            <h2>Integrity and Ethics</h2>
            <p>As a company we operate with integrity and ethical conduct by practising and treating employees, customers,
              and partners with respect and fairness, and adhering to legal and ethical standards.
              Upholding integrity and ethics helps build trust with clients, fosters long-term relationships,
              and contributes to the company's overall reputation and success.</p>
          </div>
        </div>
      </AboutDetails>

      <Values>
        <div>
          <div className="image">
            <img src="/images/about/about-zegates.jpg" alt="" />
          </div>
        </div>
        <div>
          <h2>What you can expect</h2>
          <p>A long-term partnership with you, delivering high-quality software solutions that 
            contribute to their success and provide ongoing support and collaboration to meet evolving needs.</p>
        </div>
      </Values>
    </>
  )
}

export default About;